import './App.css';
import { ReactComponent as GreenUnderscore } from './SVGs/underscore_green.svg';
import { ReactComponent as BGLogo } from './SVGs/BilateralGames.svg';
import { ReactComponent as IGLogo } from './SVGs/Instagram.svg';
import { ReactComponent as TikTokLogo } from './SVGs/Tiktok.svg';
import { ReactComponent as Phone } from './SVGs/Phone.svg';
import { ReactComponent as Place } from './SVGs/Place.svg';
import { ReactComponent as Mail } from './SVGs/Mail.svg';
import { ReactComponent as WhiteUnderline } from './SVGs/white_line.svg';


const App = () => {
  return (
    <div className="App">
      <div className="top">
        <div className="logo_container">
          <div className="logo">
            <BGLogo className="bgLogo" />
          </div>
          <div className="slogan_div">
            <p className="slogan">Unleash the power of game</p>
            <GreenUnderscore className="greenUnderscore" />
          </div>

        </div>
        <div className="socials_div">
          <div>
            <a href="https://www.instagram.com/bilateralgames/" target="_blank" rel="noopener noreferrer">
              <IGLogo className="socialLogos" />
            </a>
          </div>
          <div>
            <a href="https://www.tiktok.com/@bilateralgames_official" target="_blank" rel="noopener noreferrer">
              <TikTokLogo className="socialLogos" />
            </a>
          </div>
        </div>
      </div>
      <div className="aboutUs">
        <h1>About Us</h1>
        <div>
          <p >We are a team of passionate creatives who came together to form Bilateral Games in 2023.
            Our name encapsulates the essence of what we do - we're here not just to create games,
            but to bring them to players for their enjoyment. Our passion for gaming unites us and
            motivates us to create unforgettable experiences for all gamers.<br /><br /> Since our founding,
            we've been committed to unleashing the power of games. We strive to bring players not
            only entertainment but also new worlds, adventures, and unexpected experiences.
            We believe that games have the power to inspire, engage, and connect people.<br /><br />
            Our motto, "Unleash the Power of Game", reflects our commitment to revealing the
            potential that resides in games. We aim to enable players to enter fascinating worlds
            where they can explore, grow, and live out adventures that would otherwise be impossible.<br /><br />
            Thank you for joining us on this journey of game creation and play.
            We look forward to what we will bring to the world of video games together!
          </p>
        </div>
      </div>

      <div className="contact_div">
        <div className="contact">
          <div> <Phone className="contactIcon" />
            <p className="contactText">+420 602 429 306</p>
          </div>
          <div>
            <Mail className="contactIcon" />
            <p className="contactText">info<span className="at">@</span>BilateralGames.com</p>
          </div>
          <div>
            <Place className="contactIcon" />
            <p className="contactText">Brno, Czech republic</p>
          </div>
        </div>
      </div>
      <WhiteUnderline className="whiteUnderline" />
      <div className="footer">
        Copyright 2023 All Rights Reserved
      </div>

    </div >
  );
}

export default App;
